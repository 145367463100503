import { SEND_LEAD_FORM } from './actionTypes'
import { setAlert } from './alert';



export const sendLeadForm = (formData) => async dispatch => {
    try {

        /* const config = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": '*'
            }
        }; */

        const body = JSON.stringify(formData);

        //const res = axios.get(`https://dev.oe-konzept.de/bodyshake/www/website_relaunch/php/sendmail.php`, body, config);

        dispatch(setAlert('Ihre Anfrage wurde gesendet!', 'success'));
        const url = `${process.env.API_HOST}/sendmail.php`;
        const rawResponse = await fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/json'
            },
            body: body
        });
        const data = await rawResponse.json();

        //const data = ( await res ).data;



        dispatch({
            type: SEND_LEAD_FORM,
            payload: data
        })

    } catch (error) {
        console.log(error);
        /* dispatch({
            type: SEND_LEAD_FORM_ERROR,
            payload: {
                msg: error.response.statusText,
                status: error.response.status
            }
        }) */
    }
}