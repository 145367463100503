import { SET_ALERT, REMOVE_ALERT } from "./actionTypes";
import { v4 as uuidv4 } from 'uuid';

// Thunk middleware concats the arguments msg, alertType and dispatch and injects them into the function
export const setAlert = (msg, alertType, time = 5000) => dispatch => {
  const id = uuidv4();

  dispatch({
    type: SET_ALERT,
    payload: {
      msg,
      alertType,
      id
    }
  });

  setTimeout(() => {
    dispatch({ type: REMOVE_ALERT, payload: id });
  }, time);
};